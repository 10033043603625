<template>
  <button
    @click="onClick"
    :class="getClass"
    :style="`white-space: nowrap; width: ${width}; height: ${height}`"
  >
    <loading
      :active="getLoaderState"
      :height="25"
      :opacity="0"
      :color="
        variant === 'transparent' ||
        variant === 'secondary' ||
        variant === 'light'
          ? '#333'
          : '#fff'
      "
      :loader="`bars`"
      :is-full-page="false"
      :class="`outline-none`"
    />
    <div :class="{ invisible: isLoading }">
      <slot>
        {{ text }}
      </slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    text: {
      required: false,
    },
    variant: {
      required: false,
      default: 'default',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    height: {
      type: String,
      required: false,
      default: '36px',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: [Boolean, String],
      default: true,
    },
  },
  computed: {
    getRoundedClass() {
      if (this.rounded === true) {
        return 'rounded'
      }
      if (this.rounded === false) {
        return 'rounded-none'
      }
      return 'rounded-' + this.rounded
    },

    getClass() {
      const base = `relative flex items-center justify-center text-center px-4 font-semibold text-14px leading-normal ${this.getRoundedClass} transition ease-in-out duration-150 cursor-pointer focus:outline-none `
      const disabled = 'border border-transparent bg-disabled text-white '

      if (this.disabled) {
        // console.log({ disabled })
        return `${base} ${disabled}`
      }

      // todo: make all variants have same font size
      if (this.variant === 'secondary') {
        return `${base} bg-gray-100 border border-transparent hover:border-gray-900 hover:text-gray-900 focus:bg-gray-300`
      }
      if (this.variant === 'transparent') {
        return `${base} bg-transparent text-gray-700 border border-transparent hover:text-gray-900 cursor-default-important`
      }
      if (this.variant === 'light') {
        return `${base} bg-white text-gray-700 border border-transparent hover:border-gray-900 hover:text-gray-900 focus:bg-gray-300`
      }
      if (this.variant === 'blue') {
        return `${base} text-gray-100 bg-custom-blue border border-transparent hover:border-blue-600 hover:text-white hover:opacity-75 focus:border-blue-500`
      }
      if (this.variant === 'green') {
        return `${base} text-gray-100 bg-oGreen border border-transparent hover:border-green-600 hover:text-white hover:opacity-75 focus:border-green-500`
      }
      if (this.variant === 'green-alt') {
        return `${base} text-gray-100 bg-green-alt-button text-oGreen border border-transparent hover:border-green-600 hover:text-green-600 focus:border-green-500`
      }
      if (this.variant === 'red') {
        return `${base} text-gray-100 bg-oRed border border-transparent hover:border-red-600 hover:text-white hover:opacity-75 focus:border-red-500`
      }
      if (this.variant === 'orange') {
        return `${base} text-gray-100 bg-orange-button border border-transparent hover:bg-orange-200 hover:border-orange-200 hover:text-white focus:bg-orange-300`
      }
      if (this.variant === 'orange-alt') {
        return `${base} text-gray-100 bg-orange-alt-button text-oOrange border border-transparent hover:border-orange-600 hover:text-orange-600 focus:border-orange-500`
      }

      if (this.variant === 'add-balance') {
        return `${base} text-gray-100 bg-orange-button border border-transparent hover:bg-orange-200 hover:border-orange-200 hover:text-white focus:bg-orange-300`
      }

      if (this.variant === 'gray') {
        return `${base} text-gray-100 bg-gray-400 border border-transparent hover:bg-gray-300 hover:border-gray-300 hover:text-white focus:bg-gray-300`
      }
      if (this.variant === 'gray-alt') {
        return `${base} text-gray-700 bg-gray-50 border border-gray-300 hover:bg-gray-300 hover:text-oBlack `
      }
      if (this.variant === 'gray-green') {
        return `${base} text-gray-100 bg-gray-400 border border-gray-300 hover:bg-green-600 hover:text-oWhite `
      }
      if (this.variant === 'light-green') {
        return `${base} text-gray-700 bg-gray-50 border border-gray-300 hover:bg-green-600 hover:text-oWhite `
      }
      if (this.variant === 'gray-red') {
        return `${base} text-gray-700 bg-gray-50 border border-gray-300 hover:bg-red-600 hover:text-oWhite `
      }
      if (this.variant === 'border-red-bg-gray') {
        return `${base} text-gray-700 bg-gray-50 border border-red-500 hover:bg-red-600 hover:text-oWhite `
      }

      return `${base} text-gray-100 bg-gray-900 border border-transparent hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:bg-gray-700`
    },
    getLoaderState() {
      return (
        (!this.href || this.href === 'javascript:void(0);') && this.isLoading
      )
    },
  },
  methods: {
    onClick() {
      if (this.isLoading === false && this.disabled === false) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-green-alt-button {
  background-color: #d9f0d8;
}
.bg-orange-alt-button {
  @apply bg-orange-200;
}
.bg-orange-button {
  background-color: #ff5500;
}
.bg-disabled {
  background-color: #bebebe;
}

.bg-custom-blue {
  background: #1e5dff;
}

.cursor-default-important {
  cursor: default !important;
}
</style>
